import styled from "styled-components";

export const ServiceHolder = styled.section`
    background-color:#E9EDEB;
    padding:30px 0;
`;

export const ServiceHolderAlternate = styled.section`
    background-color:transparent;
    background-image:url(assets/images/bg-service.webp);
    background-repeat: none;
    background-size:cover;
    padding:30px 0;
    @media (max-width:768px){
        .row{
            flex-direction:column-reverse;
        }
    }
`;

export const Infography = styled.div`
    position: relative;
    width:449px;
    height:441px;
    @media (max-width:768px){
        overflow:hidden;
        width:320px;
        #feature-product-image img{
            width:370px;
        }
    }
    .feature-pill{
        position:absolute;
        &#hero-pill-2{
            bottom:0;
            left:0;
            z-index:0;
            animation: pill-feature 1s ease-in-out 0.1s both;
            img{
                width:279px;
            }
        }
        &#hero-pill-1{
            top:0;
            left:45px;
            z-index:1;
            animation: pill-feature 1s ease-in-out 0.9s both;
            img{
                width:123px;
            }
        }
    }
    #feature-product-image{
        position:absolute;
        z-index:2;
        top:175px;
        left:120px;
        animation: featureImage 1s ease-in-out 1.8s both;
        img{
            width:253px;
        }
        @media (max-width:768px){
            left:60px;
        }
    }
    @keyframes pill-feature{
        0%{
            opacity:0;
            transform:translateX(-20%) translateY(20%);
        }
        100%{
            opacity:1;
            transform: none;
        }
    }
    @keyframes featureImage{
        0%{
            opacity:0;
            transform: translateX(-20%);
        }
        100%{
            opacity:1;
            transform: none;
        }
    }
`;

export const InfographyAlternate = styled.div`
    position: relative;
    width:449px;
    height:441px;
    @media (max-width:768px){
        overflow:hidden;
        width:320px;
        #feature-product-image img{
            width:370px;
        }
    }
    .feature-pill{
        position:absolute;
        &#hero-pill-2{
            bottom:0;
            right:0;
            z-index:0;
            animation: pill-feature 1s ease-in-out 0.1s both;
            img{
                width:279px;
            }
        }
        &#hero-pill-1{
            bottom:0;
            right:20px;
            z-index:1;
            animation: pill-feature 1s ease-in-out 0.9s both;
            img{
                width:123px;
            }
        }
    }
    #feature-product-image{
        position:absolute;
        z-index:2;
        top:175px;
        left:120px;
        animation: featureImage 1s ease-in-out 1.8s both;
        img{
            width:253px;
        }
        @media (max-width:768px){
            left:0;
        }
    }
    @keyframes pill-feature{
        0%{
            opacity:0;
            transform:translateX(-20%) translateY(20%);
        }
        100%{
            opacity:1;
            transform: none;
        }
    }
    @keyframes featureImage{
        0%{
            opacity:0;
            transform: translateX(-20%);
        }
        100%{
            opacity:1;
            transform: none;
        }
    }
`;

export const AttributesGrid = styled.ul`
    display:grid;
    gap:34px 29px;
    grid-template-columns:1fr 1fr;
    @media (max-width:768px){
        margin:0;
        padding:2rem 0;
    }
`;
export const AttritubeElement = styled.li`
    list-style:none;
    display:flex;
    align-items:center;
    gap:10px;
    color: #1E577B;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 127.188%;
`