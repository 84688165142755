import React, {useState} from "react";
import { useForm, ValidationError } from '@formspree/react';
import {BtnHolder, BtnText, BtnIcono} from "../ContactSection/style";
import { Modal } from 'react-bootstrap';

export const ContactModal = ({showIn, setModalClose}) => {
    const handleClose = () => setModalClose(false);
    const [state, handleSubmit] = useForm("mqkveepj");
    const [nombre, setNombre] = useState("");
    const [mail, setMail] = useState("");
    const [telefono, setTelefono] = useState("");
    const [asunto, setAsunto] = useState("");
    const [mensaje, setMensaje] = useState("");

    if (state.succeeded) {
        return (
            <Modal 
                show={showIn} 
                onHide={handleClose} 
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="fs-2 text-center">¡Muchas gracias!</h3>
                    <p className="text-center">En breve nos comunicaremos contigo.</p>
                </Modal.Body>
            </Modal>
        );
    }
    return (
            <Modal 
                show={showIn} 
                onHide={handleClose} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h2>Contáctanos</h2>
                </Modal.Header>
                <Modal.Body>
                    <form action="https://formspree.io/f/mqkveepj" method="POST" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="nombre" className="form-label">Nombre</label>
                            <input type="text" className="form-control" value={nombre} onChange={e => setNombre(e.target.value)} name="nombre" id="nombre" placeholder="Ingresa tu nombre" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">E-mail</label>
                            <input type="email" className="form-control" value={mail} onChange={e => setMail(e.target.value)} name="email" id="email" placeholder="Ingresa tu email" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="telefono" className="form-label">Teléfono</label>
                            <input type="phone" className="form-control" value={telefono} onChange={e => setTelefono(e.target.value)} name="telefono" id="telefono" placeholder="Ingresa tu teléfono" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="asunto" className="form-label">Asunto</label>
                            <input type="text"
                                className="form-control" value={asunto} onChange={e => setAsunto(e.target.value)} name="asunto" id="asunto" aria-describedby="helpId" placeholder="Ingresa tu asunto" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Mensaje</label>
                            <textarea className="form-control" value={mensaje} onChange={e => setMensaje(e.target.value)} name="message" id="message" rows="3" placeholder="Ingresa tu mensaje"></textarea>
                        </div>
                        <div className="mb-3">
                            <BtnHolder aria-label={"Contáctanos"} type="submit" disabled={state.submitting}>
                                <BtnText>Contáctanos</BtnText>
                                <BtnIcono>
                                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L5.5 5.5L1 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </BtnIcono>
                            </BtnHolder>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
    )
}