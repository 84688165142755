import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { LogoHolder } from "./style";

const Footer = () => {

    const result = useStaticQuery(graphql`
        query FooterLogo {
            contentfulLayoutComponents {
                linkedln
                twitter
                youtube
                facebook
                instagram
                logoFooter {
                    file {
                    url
                    }
                }
            }
        } 
    `)


    return (
        <footer className="bg-dark text-white py-5">
            <div className="container">
                <LogoHolder><img src={result.contentfulLayoutComponents.logoFooter.file.url} alt={result.contentfulLayoutComponents.logoFooter.title} /></LogoHolder>
                <div className="row pt-3 pb-1">
                    <ul className="list-inline d-flex align-items-center justify-content-center w-100 gap-3">
                        {!!result.contentfulLayoutComponents.linkedln !== '#' && (<li>
                            <a href={result.contentfulLayoutComponents.linkedln} target="blank" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M16 8.80322C17.5913 8.80322 19.1174 9.43536 20.2426 10.5606C21.3679 11.6858 22 13.2119 22 14.8032V21.8032H18V14.8032C18 14.2728 17.7893 13.7641 17.4142 13.389C17.0391 13.0139 16.5304 12.8032 16 12.8032C15.4696 12.8032 14.9609 13.0139 14.5858 13.389C14.2107 13.7641 14 14.2728 14 14.8032V21.8032H10V14.8032C10 13.2119 10.6321 11.6858 11.7574 10.5606C12.8826 9.43536 14.4087 8.80322 16 8.80322Z" stroke="#F7FFE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6 9.80322H2V21.8032H6V9.80322Z" stroke="#F7FFE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4 6.80322C5.10457 6.80322 6 5.90779 6 4.80322C6 3.69865 5.10457 2.80322 4 2.80322C2.89543 2.80322 2 3.69865 2 4.80322C2 5.90779 2.89543 6.80322 4 6.80322Z" stroke="#F7FFE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </li>)}
                        {!!result.contentfulLayoutComponents.instagram !== '#' && (<li>
                            <a href={result.contentfulLayoutComponents.instagram} target="blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M17 2.80322H7C4.23858 2.80322 2 5.0418 2 7.80322V17.8032C2 20.5646 4.23858 22.8032 7 22.8032H17C19.7614 22.8032 22 20.5646 22 17.8032V7.80322C22 5.0418 19.7614 2.80322 17 2.80322Z" stroke="#F7FFE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16 12.1733C16.1234 13.0055 15.9813 13.8555 15.5938 14.6023C15.2063 15.3491 14.5932 15.9547 13.8416 16.333C13.0901 16.7112 12.2385 16.8429 11.4078 16.7092C10.5771 16.5755 9.80977 16.1834 9.21485 15.5884C8.61993 14.9935 8.22774 14.2262 8.09408 13.3955C7.96042 12.5648 8.09208 11.7132 8.47034 10.9617C8.8486 10.2101 9.4542 9.59702 10.201 9.20952C10.9478 8.82202 11.7978 8.67987 12.63 8.80328C13.4789 8.92916 14.2649 9.32474 14.8717 9.93159C15.4785 10.5384 15.8741 11.3244 16 12.1733Z" stroke="#F7FFE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M17.5 7.30322H17.51" stroke="#F7FFE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </li>)}
                        {result?.contentfulLayoutComponents?.twitter !== '#' && (<li>
                            <a href={result.contentfulLayoutComponents.twitter} target="blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M7.539 23.3033C16.596 23.3033 21.5505 15.7988 21.5505 9.30229C21.5505 9.09229 21.5505 8.87929 21.5415 8.66929C22.5061 7.97101 23.3386 7.10651 24 6.11629C23.099 6.51356 22.1441 6.77539 21.1665 6.89329C22.1963 6.27774 22.9676 5.30924 23.337 4.16779C22.3695 4.74097 21.3105 5.14335 20.2065 5.35729C19.4643 4.56682 18.4821 4.04316 17.4121 3.86742C16.3421 3.69168 15.2441 3.87367 14.288 4.38519C13.3319 4.89672 12.5712 5.70923 12.1237 6.69688C11.6761 7.68452 11.5668 8.79218 11.8125 9.84829C9.85461 9.75011 7.93922 9.24149 6.19056 8.3554C4.4419 7.46931 2.89903 6.22555 1.662 4.70479C1.03401 5.78941 0.842361 7.07239 1.12597 8.29318C1.40958 9.51398 2.14718 10.5811 3.189 11.2778C2.40831 11.2513 1.64478 11.0416 0.96 10.6658V10.7333C0.961346 11.8695 1.35496 12.9704 2.07431 13.8499C2.79366 14.7294 3.79462 15.3336 4.908 15.5603C4.48539 15.6767 4.04884 15.7347 3.6105 15.7328C3.30148 15.7337 2.99307 15.7051 2.6895 15.6473C3.00418 16.6254 3.61691 17.4805 4.44187 18.093C5.26683 18.7055 6.2627 19.0446 7.29 19.0628C5.54483 20.4335 3.3891 21.1769 1.17 21.1733C0.778981 21.1749 0.388235 21.1524 0 21.1058C2.25227 22.5417 4.86795 23.3041 7.539 23.3033Z" fill="#F7FFE9"/>
                                </svg>
                            </a>
                        </li>)}
                        {result.contentfulLayoutComponents.youtube !== '#' && (<li>
                            <a href={result.contentfulLayoutComponents.youtube} target="blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <g clipPath="url(#clip0_21_1253)">
                                <path d="M12.0765 3.80176H12.21C13.443 3.80626 19.6905 3.85126 21.375 4.30426C21.8842 4.44251 22.3483 4.71199 22.7207 5.08577C23.0932 5.45955 23.361 5.92453 23.4975 6.43426C23.649 7.00426 23.7555 7.75876 23.8275 8.53726L23.8425 8.69326L23.8755 9.08326L23.8875 9.23926C23.985 10.6103 23.997 11.8943 23.9985 12.1748V12.2873C23.997 12.5783 23.9835 13.9493 23.8755 15.3773L23.8635 15.5348L23.85 15.6908C23.775 16.5488 23.664 17.4008 23.4975 18.0278C23.3615 18.5377 23.0938 19.0029 22.7212 19.3767C22.3487 19.7506 21.8844 20.0199 21.375 20.1578C19.635 20.6258 13.0215 20.6588 12.105 20.6603H11.892C11.4285 20.6603 9.5115 20.6513 7.5015 20.5823L7.2465 20.5733L7.116 20.5673L6.8595 20.5568L6.603 20.5463C4.938 20.4728 3.3525 20.3543 2.622 20.1563C2.11273 20.0185 1.6486 19.7494 1.27609 19.3759C0.903575 19.0023 0.63577 18.5374 0.4995 18.0278C0.333 17.4023 0.222 16.5488 0.147 15.6908L0.135 15.5333L0.123 15.3773C0.0489714 14.3609 0.0079498 13.3423 0 12.3233L0 12.1388C0.003 11.8163 0.015 10.7018 0.096 9.47176L0.1065 9.31726L0.111 9.23926L0.123 9.08326L0.156 8.69326L0.171 8.53726C0.243 7.75876 0.3495 7.00276 0.501 6.43426C0.63704 5.92433 0.904743 5.45912 1.27727 5.08528C1.6498 4.71144 2.11405 4.4421 2.6235 4.30426C3.354 4.10926 4.9395 3.98926 6.6045 3.91426L6.8595 3.90376L7.1175 3.89476L7.2465 3.89026L7.503 3.87976C8.93056 3.83382 10.3587 3.80832 11.787 3.80326H12.0765V3.80176ZM9.6 8.61676V15.8438L15.8355 12.2318L9.6 8.61676Z" fill="#F7FFE9"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_21_1253">
                                <rect width="24" height="24" fill="white" transform="translate(0 0.803223)"/>
                                </clipPath>
                                </defs>
                                </svg>
                            </a>
                        </li>)}
                    </ul>
                </div>
                <div className="row py-3">
                    <p className="text-center text-white"><small>© Mozak Innovative</small></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;