import React, {useState} from "react";
import { ContactSectionHolder, TitleContactSection, ParagraphContactSection, BtnHolder, BtnText, BtnIcono, ImageContactHolder } from "./style";
import { ContactModal } from "../ContactModal";

export const ContactSection = () => {
    const [openModal, setOpenModal ] = useState(false);

    function handleModal(){
        setOpenModal(!openModal);
    }
    function closeModal(){
        setOpenModal(false);
    }

    return (
        <>
        <ContactSectionHolder>
            <div className="container position-relative">
                <TitleContactSection>Contáctanos</TitleContactSection>
                <ParagraphContactSection>Ponte en contacto con nosotros y en breve nos comunicaremos contigo</ParagraphContactSection>
                <div className="text-center d-flex justify-content-center pt-2">
                    <BtnHolder aria-label={"Contáctanos"}  onClick={handleModal}>
                        <BtnText>Contáctanos</BtnText>
                        <BtnIcono>
                            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L5.5 5.5L1 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </BtnIcono>
                    </BtnHolder>
                </div>
                <ImageContactHolder>
                    <img src="assets/images/phone.webp" alt="Contáctanos" />
                </ImageContactHolder>
            </div>
        </ContactSectionHolder>
        <ContactModal showIn={openModal} setModalClose={closeModal} />
        </>
        
    )
}