import styled from "styled-components";

export const ServiceHolder = styled.section`
    .swiper-pagination {
        position:relative;
        margin-top:2rem;
        span.swiper-pagination-bullet{
            width:19px;
            height:19px;
            &.swiper-pagination-bullet-active{
                background-color: var(--colorsecundario);
            }   
        }
    }
`;

export const ServiceGroupWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap:10px;
    width:100%;
    padding-top: 50px;
    padding-bottom:51px;
    >div:nth-child(2n){
        background-color: var(--colorprimario);
    }
`;

export const ServiceOption = styled.div`
    background-color: var(--colorsecundario);
    color: #fff;
    border-radius:8px;
    text-decoration:none;
    text-decoration:none !important;
    @media (max-width:768px){
        margin-top:60px;
    }
`;

export const ServiceOptionInner = styled.div`
    padding:0 20px 58px;
    margin: 0 auto;
    display:flex;
    flex-direction:column;
    gap: 10px;
    h2{
        color: #F4F4F4;
        font-family: League Gothic;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align:center;
        text-decoration:none !important;
    }
    p{
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; 
        text-align:center;
        text-decoration:none !important;
    }
`;

export const ServiceImageHolder = styled.div`
    display:flex;
    align-items:flex-end;
    justify-content:center;
    width:100%;
    max-width:202px;
    height:169px;
    margin:0 auto;
    text-decoration:none !important;
    img{
        width:100%;
    }
`;