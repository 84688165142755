import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { LogoHolder, LogoImage } from "./style";

const Logo = () => {
    const result = useStaticQuery(graphql`
        query HeaderLogo {
            contentfulLayoutComponents {
                logo {
                    file {
                    url
                    details {
                        image {
                        width
                        height
                        }
                    }
                    }
                    title
                }
            }
        }  
    `)
    return (
        <LogoHolder>
            <LogoImage><img src={result.contentfulLayoutComponents.logo.file.url} alt={result.contentfulLayoutComponents.logo.title} /></LogoImage>
        </LogoHolder>
    )
}

export default Logo;