import React from "react";
import { GroupTitle } from "../globalstyle";
import { ServiceHolder, Infography, InfographyAlternate, AttributesGrid, AttritubeElement, ServiceHolderAlternate } from "./style";

export const ServiceSection = ({id, title, subtitle, aternate = false, productImage, bigPill, floatPill, atributeList}) => {

    

    if(aternate){
        return (
            <ServiceHolderAlternate id={id}>
                <div className="container">
                    <GroupTitle>
                        <h2 className="title" dangerouslySetInnerHTML={{__html : title}}></h2>
                        <p className="subtitle" dangerouslySetInnerHTML={{__html : subtitle}}></p>
                    </GroupTitle>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <AttributesGrid>
                                {atributeList?.map(function(atribute, index){
                                    return (<AttritubeElement key={index}>
                                        <img srcSet={atribute.icon.file.url} alt={atribute.title} />
                                        {atribute.title}
                                    </AttritubeElement>)
                                })}
                            </AttributesGrid>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <InfographyAlternate>
                                <div className="feature-product" id="feature-product-image"><img srcSet={productImage.images.sources[0].srcSet} alt={subtitle} /></div>
                                <div className="feature-pill" id="hero-pill-1"><img srcSet={floatPill.images.sources[0].srcSet} alt={subtitle} /></div>
                                <div className="feature-pill" id="hero-pill-2"><img srcSet={bigPill.images.sources[0].srcSet} alt={subtitle}  /></div>
                            </InfographyAlternate>
                        </div>
                    </div>
                </div>
            </ServiceHolderAlternate>
        )
    }else{
        //No alternate
        return (
            <ServiceHolder  id={id}>
                <div className="container">
                    <GroupTitle>
                        <h2 className="title" dangerouslySetInnerHTML={{__html : title}}></h2>
                        <p className="subtitle" dangerouslySetInnerHTML={{__html : subtitle}}></p>
                    </GroupTitle>
                    <div className="row align-items-center">
                        <div className="col-md-6 d-flex justify-content-center">
                            <Infography>
                                <div className="feature-product" id="feature-product-image"><img srcSet={productImage.images.sources[0].srcSet} alt={subtitle} /></div>
                                <div className="feature-pill" id="hero-pill-1"><img srcSet={floatPill.images.sources[0].srcSet} alt={subtitle} /></div>
                                <div className="feature-pill" id="hero-pill-2"><img srcSet={bigPill.images.sources[0].srcSet} alt={subtitle} /></div>
                            </Infography>
                        </div>
                        <div className="col-md-6">
                            <AttributesGrid>
                                {atributeList?.map(function(atribute, index){
                                    return (<AttritubeElement key={index}>
                                        <img srcSet={atribute.icon.file.url} alt={atribute.title} />
                                        {atribute.title}
                                    </AttritubeElement>)
                                })}
                            </AttributesGrid>
                        </div>
                    </div>
                </div>
            </ServiceHolder>
        )
    }
    
}