import styled from "styled-components";


export const LogoHolder = styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    max-width: 374px;
    position:absolute;
    z-index:2;
    &:after{
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="35" height="91" viewBox="0 0 35 91" fill="none">
        <path d="M0 0H35L17.6943 76.6268C15.8265 84.8973 8.47874 90.7706 0 90.7706V0Z" fill="white"/></svg>');
        display:block;
        width:35px;
        height:91px;
        transform: translateX(-1px);
        filter: drop-shadow(2px 4px 2px rgba(0, 0, 0, 0.25));
        z-index:0;
    }
`;

export const LogoImage = styled.div`
    display:flex;
    align-items:center;
    justify-content: flex-end;
    padding-left:130px;
    padding-right:1px;
    background-color:#fff;
    height:91px;
    box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @media (max-width:768px){
        padding-left:10vw;
        img{
            width:130px;
        }
    }
`;