import styled from "styled-components";

export const ContactSectionHolder = styled.section`
    background-image: url(assets/images/bg-contact.jpg);
    background-repeat: none;
    background-position:center center;
    background-size:cover;
    .container{
        padding:3rem 0;
    }
    @media (max-width:768px){
        .container{
            padding-bottom:0;
        }
    }
`;

export const TitleContactSection = styled.h3`
    color: #FFF;
    font-family: League Gothic;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align:center;
`;

export const ParagraphContactSection = styled.p`
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 127.188%;
`;

export const BtnHolder = styled.button`
    display: flex;
    align-items: stretch;
    text-decoration: none !important;
    transition: all 0.3s ease 0s;
    background: none !important;
    border: 0 none !important;
    outline: none !important;
    padding: 0;
    &:hover{
        cursor: pointer;
    }
`;

export const BtnText = styled.span`
    height: 37px;
    background-color: #66BA93;
    font-family: "Sofia Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    text-decoration: none !important;
    padding: 10px 12px 10px 21px;
    border-radius: 15px 0px 0px 15px;
    box-sizing: border-box;
    transition: all 0.3s ease 0s;
    text-wrap: nowrap;
    &:hover{
        color:#fff;
        background-color: #333;
    }
`;

export const BtnIcono = styled.span`
    height: 37px;
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    border-radius: 0px 15px 15px 0px;
    transition: all 0.3s ease 0s;
    svg{
        width:21px;
    }
    &:hover{
        background-color: #1E577B;
    }
`;

export const ImageContactHolder = styled.div`
    position:absolute;
    bottom:0;
    left:0;
    @media (max-width:768px){
        position:relative;
        display:flex;
        align-items:flex-end;
        justify-content:center;
        margin-top:2rem;
    }
`