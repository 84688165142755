import styled from "styled-components";

export const HeaderWrapper = styled.header`
        width:100vw;
        position:fixed;
        top:0;
        left:0;
        z-index:9;
`;

export const HeaderContainer = styled.div`
        background-color:var(--colorprimario);
        width:100vw;
        color: #fff;
        padding:.5rem 0;
`;

export const HeaderInner = styled.div`
        width:100%;
        padding: 0 1rem;
        display: flex;
        flex-direction:row;
        align-items: center;
        justify-content: flex-end;
        gap:46px;
`;