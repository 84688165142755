import React from "react";
import { HeroSection, BgImage, ContentHero, HeroPillContainer } from "./style";

export const Hero = ({preheading, heading, subheading, backgroundImage}) => {
    return (
    <HeroSection data-image-src={backgroundImage.images.sources[0].srcSet}>
            <article className="container">
                <div className="row flex-column-reverse flex-lg-row">
                    <ContentHero className="col-lg-5">
                        <h2 className="pre-heading">{preheading}</h2>
                        <h1 className="heading" dangerouslySetInnerHTML={{__html : heading}}></h1>
                        <p className="subheading">{subheading}</p>
                    </ContentHero>
                    <div className="col-lg-7 position-relative overflow-hidden">
                        <HeroPillContainer >
                            <div className="hero-pill" id="hero-pill-1"><img src="assets/images/caluga-hero-1.webp" alt={preheading} /></div>
                            <div className="hero-pill" id="hero-pill-2"><img src="assets/images/caluga-hero-2.webp" alt={preheading}  /></div>
                            <div className="hero-pill" id="hero-pill-3"><img src="assets/images/caluga-hero-3.webp" alt={preheading} /></div>
                            <div className="hero-pill" id="hero-pill-4"><img src="assets/images/caluga-hero-4.webp" alt={preheading} /></div>
                        </HeroPillContainer>
                    </div>
                </div>
            </article>
            <BgImage>
                <img srcSet={backgroundImage.images.sources[0].srcSet} alt={heading} />
            </BgImage>
    </HeroSection>
    );
}