import styled from "styled-components";

export const HeroSection = styled.section`
    background-image: attr(data-image-src url);
    width:100%;
    position:relative;
    min-height:598px;
`;

export const BgImage = styled.div`
    position:absolute;
    top: 0;
    left:0;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:-1;
    overflow:hidden;
    height:100%;
    width:100%;
    img{
        height:100%;
    }
`;

export const ContentHero = styled.div`
    padding-top:250px;
    @media(max-width:768px){
        padding-top:0;
    }
    .pre-heading{
        color: var(--colorsecundario);
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-wrap: nowrap;
        gap: 17px;
        &:after{
            content:'';
            display:block;
            height:1px;
            width:100%;
            background-color:var(--colorprimario);
            border:0 none;
            animation: anim-line 1s ease-in-out 0.5s both;
        }
    }
    .heading{
        color: var(--colorprimario);
        font-family: League Gothic;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        animation: anim-heading 0.6s ease-in-out 0.1s both;
        span{
            color: var(--colorsecundario);
        }
    }
    .subheading{
        color: #333;
        text-align: justify;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        animation: anim-heading 0.6s ease-in-out 0.5s both;
    }
    @keyframes anim-heading{
        0%{
            opacity:0;
            margin-top:25px;
        }
        100%{
            opacity:1;
            margin-top:0;
        }
    }
    @keyframes anim-line{
        0%{
            width:0;
        }
        100%{
            width:100%;
        }
    }
`;

export const HeroPillContainer = styled.div`
    position:relative;
    width:506px;
    height:598px;
    margin:0 auto;
    @media(max-width:768px){
        transform: translateX(-16%) scale(0.8);
    }
    .hero-pill{
        position:absolute;
        &#hero-pill-1{
            bottom:0;
            left:100px;
            animation: pill-1 1s ease-in-out 0.1s both;
            z-index:0;
        }
        &#hero-pill-2{
            bottom:40px;
            left:30px;
            animation: pill-2 1s ease-in-out 0.5s both;
            z-index:1;
        }
        &#hero-pill-3{
            bottom:180px;
            left:30px;
            animation: pill-2 1s ease-in-out 1s both;
            z-index:1;
        }
        &#hero-pill-4{
            bottom:0
            ;
            right:0;
            animation: pill-2 1s ease-in-out 0.9s both;
            z-index:1;
        }
    }
    @keyframes pill-1{
        0%{
            opacity:0;
            transform:translateX(20%) translateY(-20%);
        }
        100%{
            opacity:1;
            transform: none;
        }
    }
    @keyframes pill-2{
        0%{
            opacity:0;
            transform:translateX(50%) translateY(-50%);
        }
        100%{
            opacity:1;
            transform: none;
        }
    }
`;