import React from "react";
import { Hero, ServiceGroup, FeatureProduct, ServiceSection } from "components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {BLOCKS} from '@contentful/rich-text-types';
import { GatsbyImage } from "gatsby-plugin-image";

export const RichText = ({raw, references = []}) => {

    const referencesMap = {};
    references.forEach(reference => {
        referencesMap[reference.contentful_id] = reference;
    });

    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const data = referencesMap[node.data.target.sys.id];
                return <GatsbyImage alt={data.title} image={data.gatsbyImageData}/>
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                const data = referencesMap[node.data.target.sys.id];
                switch(data.__typename){
                    case 'ContentfulHero':
                        return (
                            <Hero 
                                preheading={data.preHeading} 
                                heading={data.heading} 
                                subheading={data.subHeadingText.subHeadingText} 
                                backgroundImage={data.backgroundImage.gatsbyImageData} 
                            />
                        );
                    case "ContentfulServicesGroup":
                        return <ServiceGroup serviceOptions={data.serviceOptions} title={data.title} subtitle={data.subtitle} />
                    case "ContentfulFeatureProduct":
                        return (
                            <FeatureProduct 
                                title={data.title}
                                subtitle={data.subtitle}
                                text={data.text.text}
                                videoURL={data.video.file.url}
                            />
                        )
                    case "ContentfulService":
                        return (
                                <ServiceSection 
                                id={data.contentful_id}
                                title={data.title}
                                subtitle={data.subtitle}
                                aternate={data.aternate}
                                productImage={data.productImage.gatsbyImageData} 
                                bigPill={data.bigPill.gatsbyImageData} 
                                floatPill={data.floatPill.gatsbyImageData} 
                                atributeList={data.atributeList.atributes}
                                />
                        )
                    default :
                        return null;
                }
            }
        }
    }

    return <div>{documentToReactComponents( JSON.parse(raw), options)}</div>;
}