import styled from "styled-components";

export const FeatureProductHolder = styled.section`
    background: transparent;
    padding:30px 0;
`;

export const Infography = styled.div`
    position: relative;
    width:449px;
    height:441px;
    @media (max-width:768px){
        overflow:hidden;
        width:320px;
        #feature-product-image img{
            width:370px;
        }
    }
    .feature-pill{
        position:absolute;
        &#hero-pill-2{
            bottom:0;
            left:0;
            z-index:0;
            animation: pill-feature 1s ease-in-out 0.1s both;
        }
        &#hero-pill-1{
            top:0;
            left:45px;
            z-index:1;
            animation: pill-feature 1s ease-in-out 0.9s both;
        }
    }
    #feature-product-image{
        position:absolute;
        z-index:2;
        top:175px;
        right:0;
        animation: featureImage 1s ease-in-out 1.8s both;
    }
    @keyframes pill-feature{
        0%{
            opacity:0;
            transform:translateX(-20%) translateY(20%);
        }
        100%{
            opacity:1;
            transform: none;
        }
    }
    @keyframes featureImage{
        0%{
            opacity:0;
            transform: translateX(-20%);
        }
        100%{
            opacity:1;
            transform: none;
        }
    }
`;

export const FeatureTitle = styled.h2`
    color: var(--colorprimario);
    font-family: League Gothic;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    span{
        color: var(--colorsecundario);
    }
    @media (max-width:768px){
        text-align:center;
        width:100%;
    }
`;

export const FeatureSubTitle = styled.h3`
    color: #1E577B;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 127.188%; /* 17.806px */
    text-transform: capitalize;
    @media (max-width:768px){
        text-align:center;
        width:100%;
    }
`;

export const FeatureText = styled.p`
    color: #333;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width:768px){
        text-align:center;
        width:100%;
    }
`;