import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { InfoItem } from "./style";
import LocationIcon from "./icons/location";
import MailIcon from "./icons/mail";
import PhoneIcon from './icons/phone';
import { ContactModal } from "../../../ContactModal";

const Info = () => {
    const [openModal, setOpenModal ] = useState(false);

    function handleModal(){
        setOpenModal(!openModal);
    }
    function closeModal(){
        setOpenModal(false);
    }

    const result = useStaticQuery(graphql`
        query HeaderInfoContent {
            contentfulLayoutComponents {
            eMailAdress
            fisicalAdress
            phoneNumber
            }
        }    
    `)
    return (
        <>
            { result.contentfulLayoutComponents.eMailAdress && (
                <a onClick={handleModal} style={{'cursor': 'pointer'}}>
                    <InfoItem className="d-none d-md-flex">
                        <MailIcon/>
                        {result.contentfulLayoutComponents.eMailAdress}
                    </InfoItem>
                </a>
            )} 
            { result.contentfulLayoutComponents.fisicalAdress && (
                <a href={`https://maps.google.com/?q=${result.contentfulLayoutComponents.fisicalAdress}`} target="blank" style={{'textDecoration': 'none'}}>
                    <InfoItem className="d-none d-lg-flex">
                        <LocationIcon/>
                        {result.contentfulLayoutComponents.fisicalAdress}
                    </InfoItem>
                </a>
            )} 
            { result.contentfulLayoutComponents.phoneNumber && (
                <a href={`https://wa.me/${result.contentfulLayoutComponents.phoneNumber.replace(/\D/g, '')}`} style={{'textDecoration': 'none'}}>
                    <InfoItem>
                        <PhoneIcon/>
                        {result.contentfulLayoutComponents.phoneNumber}
                    </InfoItem>
                </a>
            )} 
            <ContactModal showIn={openModal} setModalClose={closeModal} />
        </>
    )
}

export default Info;