import styled from "styled-components";

export const BtnHolder = styled.button`
    display: flex;
    align-items: stretch;
    text-decoration: none !important;
    transition: all 0.3s ease 0s;
    background: none !important;
    border: 0 none !important;
    outline: none !important;
    padding: 0;
    &:hover{
        cursor: pointer;
    }
`;

export const BtnText = styled.span`
    height: 37px;
    background-color: #66BA93;
    font-family: "Sofia Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    text-decoration: none !important;
    padding: 10px 12px 10px 21px;
    border-radius: 15px 0px 0px 15px;
    box-sizing: border-box;
    transition: all 0.3s ease 0s;
    text-wrap: nowrap;
    &:hover{
        color:#fff;
        background-color: #1E577B;
    }
`;

export const BtnIcono = styled.span`
    height: 37px;
    background-color: #1E577B;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    border-radius: 0px 15px 15px 0px;
    transition: all 0.3s ease 0s;
    svg{
        width:21px;
    }
    &:hover{
        background-color: #1E577B;
    }
`;