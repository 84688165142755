import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createGlobalStyle } from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import { ContactSection } from "../ContactSection";

const GlobalStyle = createGlobalStyle`
:root{
  --colorprimario: #1E577B;
  --colorsecundario: #66BA93;
}
  body{
    font-family: 'Lato', sans-serif;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    
  }
  a{
    text-decoration: none !important;
  }
`;

export const Layout = ({ children }) => {
    return (
      <>
        <Header/>
        <div className="main">
            <GlobalStyle />
            <section>{children}</section>
            <ContactSection/>
        </div>
        <Footer />
      </>
        
    );
};
