import React from "react";
import { HeaderWrapper, HeaderInner, HeaderContainer } from "./style";
import Info from "./Info";
import Logo from "./Logo";

const Header = () => {
    return (
        <HeaderWrapper>
            <Logo/>
            <HeaderContainer>
                <HeaderInner className="container" id="headerContent"><Info /></HeaderInner>
            </HeaderContainer>
        </HeaderWrapper>
    )
}

export default Header;