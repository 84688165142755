import React, {useEffect, useState} from "react";
import { ServiceGroupWrapper, ServiceOption, ServiceOptionInner, ServiceImageHolder, ServiceHolder  } from "./style";
import { GroupTitle } from "../globalstyle";
import {RichText} from "components";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import 'swiper/css';
import 'swiper/css/pagination';

export const ServiceGroup = ({serviceOptions, title, subtitle}) => {
    const [isMobile, setIsMobile] = useState();

    useEffect(() => {
        setIsMobile(window.innerWidth <= 1024);
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };
            window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])


    if(isMobile){
        return (
            <ServiceHolder>
                <article className="container">
                    <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                        {serviceOptions?.map(serviceOption => {
                            return (<SwiperSlide key={serviceOption.id}>
                                <AnchorLink to={serviceOption.anchor}>
                                    <ServiceOption >
                                            <ServiceImageHolder><img srcSet={serviceOption.icon.gatsbyImageData.images.sources[0].srcSet} alt="" /></ServiceImageHolder>
                                            <ServiceOptionInner>
                                                    <h2>{serviceOption.title}</h2>
                                                    <RichText raw={serviceOption.text.raw}></RichText>
                                            </ServiceOptionInner>
                                        </ServiceOption>
                                </AnchorLink>
                            </SwiperSlide>);
                        })}
                    </Swiper>
                </article>
            </ServiceHolder>
        )
    }else{
        return (
            <ServiceHolder>
                <article className="container">
                    <div className="row">
                        <GroupTitle>
                                <h2 className="title" dangerouslySetInnerHTML={{__html : title}}></h2>
                                <p className="subtitle">{subtitle}</p>
                        </GroupTitle>
                        <ServiceGroupWrapper>
                            {serviceOptions?.map(serviceOption => {
                                return (
                                    <AnchorLink to={serviceOption.anchor} key={serviceOption.id}>
                                    <ServiceOption >
                                        <ServiceImageHolder><img srcSet={serviceOption.icon.gatsbyImageData.images.sources[0].srcSet} alt="" /></ServiceImageHolder>
                                        <ServiceOptionInner>
                                                <h2>{serviceOption.title}</h2>
                                                <RichText raw={serviceOption.text.raw}></RichText>
                                        </ServiceOptionInner>
                                    </ServiceOption>
                                    </AnchorLink>
                                ); 
                            })}
                        </ServiceGroupWrapper>
                    </div>
                </article>
            </ServiceHolder>
        );
    }
    
};