import styled from "styled-components";

export const InfoItem = styled.span`
    display:flex;
    align-items: flex-start;
    gap: 8px;
    text-decoration: none;
    color: #FFF;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;