import React from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'react-bootstrap';

const ModalVideo = ({showIn, videoURL, setModalClose, titleModal }) => {

    const handleClose = () => setModalClose(false);

    return (
        <>
            <Modal 
                show={showIn} 
                onHide={handleClose} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body><ReactPlayer url={videoURL} muted={false} playing={true} width={"100%"} /></Modal.Body>
            </Modal>
        </>
    )
}

export default ModalVideo;