import React from "react";
import { BtnHolder, BtnText, BtnIcono } from "./style";

const BtnVideo = ({title, toDoAction}) => {
    return (
        <BtnHolder onClick={(event) => toDoAction(event)} aria-label={title}>
            <BtnText>{title}</BtnText>
            <BtnIcono>
                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5.5 5.5L1 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </BtnIcono>
        </BtnHolder>
    )
}
export default BtnVideo;