import styled from "styled-components";

export const GroupTitle = styled.div`
    display: flex;
    max-width: 100%;
    padding: 30px 0px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    .title{
        color: var(--colorprimario);
        font-family: League Gothic;
        font-size: 40px;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin:0;
        span{
            color: var(--colorsecundario);
        }
    }
    .subtitle{
        color: var(--colorsecundario);
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 127.188%; /* 17.806px */
        span{
            color: var(--colorprimario);
        }
    }
`;