import React, { useEffect, useState } from "react";
import { FeatureProductHolder, Infography, FeatureTitle, FeatureSubTitle, FeatureText } from "./style";
import BtnVideo from "./components/btnVideo";
import ModalVideo from "./components/ModalVideo";

export const FeatureProduct = ({title, subtitle, text, videoURL}) => {
    const [openModal, setOpenModal ] = useState(false);
    const [titleModal, setTitleModal ] = useState("");

    useEffect(() => {
        setTitleModal(title);
    }, [title])

    function handleModal(idVideo, title){
        setOpenModal(!openModal);
    }
    function closeModal(){
        setOpenModal(false);
    }

    return (
        <>
            <FeatureProductHolder>
                <article className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 d-flex justify-content-center">
                                <Infography>
                                    <div className="feature-product" id="feature-product-image"><img src="assets/images/feature-image.webp" alt={subtitle} /></div>
                                    <div className="feature-pill" id="hero-pill-1"><img src="assets/images/caluga-feature-1.webp" alt={subtitle} /></div>
                                    <div className="feature-pill" id="hero-pill-2"><img src="assets/images/caluga-feature-2.webp" alt={subtitle}  /></div>
                                </Infography>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center flex-column align-items-start h-100">
                                <FeatureTitle dangerouslySetInnerHTML={{__html : title}} />
                                <FeatureSubTitle dangerouslySetInnerHTML={{__html : subtitle}} />
                                <FeatureText>{text}</FeatureText>
                                <div className="mt-4 d-flex w-100 justify-content-center justify-content-lg-start align-items-center"><BtnVideo title={"Ver video"} toDoAction={handleModal} /></div>
                            </div>
                        </div>
                </article>
            </FeatureProductHolder>
            <ModalVideo showIn={openModal} videoURL={videoURL} setModalClose={closeModal} titleModal={titleModal} />
        </>
    
    )
}